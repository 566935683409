import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Popover from "components/common/Popover";
import Menu from "components/common/Menu";
import { Table, Placeholder, List, Icon } from "semantic-ui-react";
import LABEL from "constants/label";
import * as utils from "@kdshared/okta-utils";
import CONFIG from "constants/config";
import sendEventData from "analytics/analytics";
import { EVENTS, TRIGGERS } from "analytics/constants";
import { noop } from "lodash";
import { getMemberListImage } from "redux/actions/memberList";
import "./styles.scss";

const { COMMON } = LABEL;
const { xApiKey, apiUrl } = CONFIG;

/**
 * Member
 *
 * @param  {object} Member team member details
 * @param  {func} errorCallback fires on API fetch error
 * @param  {string} origin parent component
 *
 * @return member details table row
 */

const Member = ({ member, errorCallback, origin, sortBy, getFilterterm, index, kpCmsId, className, people }) => {
  const dispatch = useDispatch();
  const { memberListLoading } = useSelector((state) => state);
  const { memberListImage } = useSelector((state) => state);
  const [highlightRow, setHighlightRow] = useState(false);
  const { hremployeeid, positiontitle } = member;

  const getMemberProfile = (url, id) => {
    const arr = url.split("/");
    return `${arr[0]}//${arr[2]}/overview?hrid=${id}`;
  };

  const fetchImage = async () => {
    try {
      dispatch(
        getMemberListImage({
          endpoint: `${apiUrl.peopleImage}${hremployeeid}`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.people}`,
              psid: utils.getPsId()
            },
            responseType: "arraybuffer"
          },
          hrid: hremployeeid,
          showToast: false
        })
      );
    } catch (error) {
      errorCallback();
    }
  };

  // const handleCopyToClipBoard = () => {
  //   if (origin === "material") {
  //     sendEventData(TRIGGERS.MATERIAL.COPY_PROFILE_URL, EVENTS.MATERIAL_SECTION, {
  //       preSortTerm: sortBy,
  //       preFilterTerm: getFilterterm(),
  //       kpid: kpCmsId
  //     });
  //   }
  // } else if (origin === "case") {
  //   sendEventData(TRIGGERS.CASE.COPY_PROF_URL, EVENTS.CASES_SECTION, {
  //     preSortTerm: sortBy,
  //     preFilterTerm: getFilterterm()
  //   });
  //   // }
  //   copyToClipboard(getMemberProfile(window.location.href, hrEmployeeId)); // Analytics Event
  // };

  const handleEmailClick = ({ workEmail }) => {
    sendEventData(TRIGGERS.CASE.EMAIL, EVENTS.CASES_SECTION, {
      preSortTerm: sortBy,
      preFilterTerm: getFilterterm()
    });
    window.location.assign(`mailto:${workEmail}`);
  };

  const handleSlackClick = ({ slackId }) => {
    sendEventData(TRIGGERS.CASE.SLACK, EVENTS.CASES_SECTION, {
      preSortTerm: sortBy,
      preFilterTerm: getFilterterm()
    });
    window.open(`https://global-bcg.slack.com/app_redirect?channel=${slackId}`, "_blank");
  };

  useEffect(() => {
    fetchImage();
  }, []);

  const menuItems = [];

  menuItems.push({
    name: COMMON.GoToProfile,
    onClick: () => {
      window.open(getMemberProfile(window.location.href, people?.id), "_blank");
    }
  });

  if (people?.alumni === "NO") {
    menuItems.push(
      {
        name: COMMON.SendAnEmail,
        onClick: () => handleEmailClick(people)
      },
      {
        name: COMMON.Slack,
        onClick: () => handleSlackClick(people)
      }
    );
    if (people?.assistants?.length) {
      menuItems.push({
        name: COMMON.ContactAssistant,
        onClick: () => window.location.assign(`mailto:${people?.assistants[0]?.email}`)
      });
    }
  }

  const trigger = <Icon className="cursor-pointer" name="ellipsis horizontal" data-testid="share" />;

  const sendAnalyticsData = (adobeTrigger, adobeEvent) => {
    sendEventData(adobeTrigger, adobeEvent, {
      preSortTerm: sortBy,
      preFilterTerm: getFilterterm(),
      kpid: origin === "material" ? kpCmsId : "NA"
    });
  };

  return (
    <>
      {/* Web */}
      <Table.Row
        verticalAlign="top"
        className={`md-none ${index % 2 === 0 ? "even" : "odd"} ${highlightRow ? "member__highlightRow" : ""} `}
        onMouseOver={() => setHighlightRow(true)}
        onMouseOut={() => setHighlightRow(false)}
      >
        <Table.Cell>
          <List divided relaxed>
            <List.Item>
              <List.Icon verticalAlign="middle">
                {memberListLoading ? (
                  <Placeholder className={`mb-0 mt-0 ${className ? `${className}` : "member-placehoder"}`} as="span">
                    <Placeholder.Image as="span" />
                  </Placeholder>
                ) : (
                  <img
                    src={memberListImage[hremployeeid]}
                    alt=""
                    className={`mb-0 mt-0 ${className ? `${className}` : "member-placehoder"}`}
                    data-testid="img"
                  />
                )}
              </List.Icon>
              <List.Content>
                <List.Header as="p">
                  {memberListLoading ? (
                    <Placeholder>
                      <Placeholder.Paragraph>
                        <Placeholder.Line />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  ) : (
                    <Popover
                      trigger={
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={getMemberProfile(window.location.href, hremployeeid)}
                          onClick={() => {
                            if (origin === "material") {
                              sendAnalyticsData(TRIGGERS.MATERIAL.VIEW_PROFILE, EVENTS.MATERIAL_SECTION);
                            } else if (origin === "case") {
                              sendAnalyticsData(TRIGGERS.CASE.VIEW_PROFILE, EVENTS.CASES_SECTION);
                            }
                          }}
                        >
                          <strong>
                            {people?.preferredFirstName} {people?.preferredLastName}
                          </strong>
                        </a>
                      }
                      on="hover"
                      inverted
                      position="bottom center"
                      size="tiny"
                      popper={{ className: "sm tags-tooltip" }}
                    >
                      {COMMON.ViewProfile}
                    </Popover>
                  )}
                </List.Header>
                <List.Description as="p">
                  {memberListLoading ? (
                    <Placeholder className="mt-h">
                      <Placeholder.Paragraph>
                        <Placeholder.Line />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  ) : (
                    <>{people?.alumni === "YES" ? "Alumni" : people?.workEmail}</>
                  )}
                </List.Description>
                {origin === "case" && (
                  <List.Description>
                    {memberListLoading ? (
                      <Placeholder className="mt-h">
                        <Placeholder.Paragraph>
                          <Placeholder.Line />
                        </Placeholder.Paragraph>
                      </Placeholder>
                    ) : (
                      <>{people?.businessTitle}</>
                    )}
                  </List.Description>
                )}
              </List.Content>
            </List.Item>
          </List>
        </Table.Cell>
        {origin === "case" ? (
          <Table.Cell className="pt-2">
            {memberListLoading ? (
              <Placeholder className="mt-h">
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                </Placeholder.Paragraph>
              </Placeholder>
            ) : (
              <>{positiontitle}</>
            )}
          </Table.Cell>
        ) : (
          <Table.Cell className="pt-2">
            {memberListLoading ? (
              <Placeholder className="mt-h">
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                </Placeholder.Paragraph>
              </Placeholder>
            ) : (
              <>{people?.businessTitle}</>
            )}
          </Table.Cell>
        )}
        <Table.Cell className="pt-2">
          {memberListLoading ? (
            <Placeholder className="mt-h">
              <Placeholder.Paragraph>
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          ) : (
            <>
              <img src="/icons/Location.svg" alt="" width={9} style={{ marginRight: "4px" }} />
              {people?.hostOfficeLocation?.name}
            </>
          )}
        </Table.Cell>
        <Table.Cell className="pt-2 action" textAlign="right">
          {people && <Menu menuItems={menuItems} trigger={trigger} origin={origin} />}
        </Table.Cell>
      </Table.Row>
      {/* Mobile */}
      <Table.Row verticalAlign="top" className={`dd-none ${index % 2 === 0 ? "even" : "odd"}`}>
        <Table.Cell className="first">
          <List divided relaxed>
            <List.Item>
              <List.Icon verticalAlign="middle">
                {memberListLoading ? (
                  <Placeholder className="mb-0 mt-0 member-placehoder" as="span">
                    <Placeholder.Image as="span" />
                  </Placeholder>
                ) : (
                  <img src={memberListImage[hremployeeid]} alt="" className="mb-0 mt-0 member-placehoder" />
                )}
              </List.Icon>
              <List.Content>
                <List.Header as="div" className="mt-h">
                  {memberListLoading ? (
                    <Placeholder>
                      <Placeholder.Paragraph>
                        <Placeholder.Line />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  ) : (
                    <>
                      <h4 className="mb-h">
                        <a
                          href={getMemberProfile(window.location.href, hremployeeid)}
                          onClick={() => {
                            if (origin === "material") {
                              sendAnalyticsData(TRIGGERS.MATERIAL.VIEW_PROFILE, EVENTS.MATERIAL_SECTION);
                            } else if (origin === "case") {
                              sendAnalyticsData(TRIGGERS.CASE.VIEW_PROFILE, EVENTS.CASES_SECTION);
                            }
                          }}
                        >
                          {people?.preferredFirstName} {people?.preferredLastName}
                        </a>
                      </h4>
                      <p>
                        {people?.businessTitle} | {people?.hostOfficeLocation?.name}
                      </p>
                    </>
                  )}
                </List.Header>
              </List.Content>
            </List.Item>
          </List>
        </Table.Cell>
        <Table.Cell className="pt-2 action" textAlign="right">
          {people && <Menu menuItems={menuItems} trigger={trigger} />}
        </Table.Cell>
        <Table.Cell className="pt-2 full-width align-item-center">
          {memberListLoading ? (
            <Placeholder className="mt-h">
              <Placeholder.Paragraph>
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          ) : (
            <>
              {!!people?.assistants?.length && (
                <>
                  Assistant :{" "}
                  <strong>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href={getMemberProfile(window.location.href, people?.assistants[0]?.id)}
                      onClick={() => {
                        if (origin === "material") {
                          sendAnalyticsData(TRIGGERS.MATERIAL.ASSISTANT_PROFILE, EVENTS.MATERIAL_SECTION);
                        } else if (origin === "case") {
                          sendAnalyticsData(TRIGGERS.CASE.ASSISTANT_PROFILE, EVENTS.CASES_SECTION);
                        }
                      }}
                    >
                      {people?.assistants[0]?.name}
                    </a>
                  </strong>
                </>
              )}
            </>
          )}
        </Table.Cell>
      </Table.Row>
    </>
  );
};

Member.defaultProps = {
  member: {},
  errorCallback: () => {},
  origin: "",
  sortBy: "",
  getFilterterm: noop,
  index: 1,
  kpCmsId: 0,
  className: ""
};

Member.propTypes = {
  member: PropTypes.shape({
    isalumni: PropTypes.string,
    hremployeeid: PropTypes.number,
    staffId: PropTypes.number,
    positiontitle: PropTypes.string
  }),
  errorCallback: PropTypes.func,
  origin: PropTypes.string,
  sortBy: PropTypes.string,
  getFilterterm: PropTypes.func,
  index: PropTypes.number,
  kpCmsId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  people: PropTypes.shape({
    id: PropTypes.string,
    alumni: PropTypes.string,
    preferredFirstName: PropTypes.string,
    preferredLastName: PropTypes.string,
    workEmail: PropTypes.string,
    businessTitle: PropTypes.string,
    assistants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string,
        name: PropTypes.string
      })
    ),
    hostOfficeLocation: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }),
    positionAsOfTitle: PropTypes.string
  }).isRequired
};

export default Member;
