import { call, put } from "redux-saga/effects";
import { post } from "redux/api";
import { setRecentSSCases, setError } from "redux/actions/ssRecentCases";

function* handleFetchSSRecentCases({ payload }) {
  const { res, error } = yield call(post, payload);
  if (error) {
    yield put(setError(error));
  } else {
    yield put(setRecentSSCases(res.data));
  }
}

export default handleFetchSSRecentCases;
